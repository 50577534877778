import * as React from "react"

interface Props {
  text?: string
  className?: string
}

const TitleWithSeparator: React.FC<Props> = ({ text, className, children }) => {
  return (
    <div
      className={
        "title-with-separator d-flex flex-column " +
        (className ? className : "")
      }
    >
      {children ? (
        children
      ) : (
        <div className="title text-center">
          <h1>{text}</h1>
        </div>
      )}
    </div>
  )
}

export default TitleWithSeparator
