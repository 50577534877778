import { store } from "../translations/store"

type Translated<T> = T extends { en: string }
  ? string
  : T extends (...args: infer P) => any
  ? (...args: P) => string
  : { [K in keyof T]: Translated<T[K]> }

export function makeTranslation<T>(obj: T): Translated<T> {
  if (!obj) return
  for (const key of Object.getOwnPropertyNames(obj)) {
    const val = obj[key]
    if (val && typeof val === "object") {
      if ("en" in val) {
        Object.defineProperty(obj, key, {
          get() {
            return val[store.language] || val.sr || ""
          },
        })
      } else {
        makeTranslation(val)
      }
    } else if (typeof val === "function") {
      obj[key] = function wrapped() {
        const res = val.apply(this, arguments)
        return res[store.language] || res.sr || ""
      }
    }
  }
  return obj as any
}
