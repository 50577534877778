export type Language = "en" | "sr"

export const languageList: {
  [lang in Language]?: { [lang in Language]?: string }
} = {
  en: {
    en: "English",
    sr: "Serbian",
  },
  sr: {
    en: "Engleski",
    sr: "Srpski",
  },
}

export const dropdownLanguages: Language[] = ["en", "sr"]

export function getLocale(): Language {
  return (
    ((
      (navigator.languages &&
        navigator.languages.length &&
        navigator.languages[0]) ||
      navigator.language
    )?.split("-")[0] as Language) || "sr"
  )
}
