import { Link } from "gatsby"
import * as React from "react"
import "./Footer.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookF,
  faYoutube,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons"
import { images, variables } from "../../data/variables"
import { translations } from "../../translations/translations"
import { observer } from "mobx-react-lite"

const Footer = observer(() => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const handleSubmit = (e) => {
    const email = inputRef.current.value;
    if (!email) return;
    fetch(`${variables.shop}/wp-json/newsletter/v2/subscribers?client_key=625c52f25ab4f01f4907be3076a557b2db3225f4&client_secret=89b407de1167fa7c95b9af41b620ffbcd36ced13`, {
      method: 'POST',
      body: JSON.stringify({
        "email": email
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(async (res) => {
      if (res.status === 201) {
        alert('Uspesno ste se prijavili na newsletter');
      } else {
        alert('Greska');
        console.log(await res.json())
      }
      console.log(res);
    }).catch(err => {
      console.error(err);
    })
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  }

  return (
    <footer>
      <div className="inner w-90">
        <div className="top d-flex align-items-center">
          <div className="logo">
            <img src={images.logo} alt="Evrotom" />
          </div>
          <div className="social social-mobile">
            <FontAwesomeIcon className="fa" icon={faYoutube} />
            <FontAwesomeIcon className="fa" icon={faFacebookF} />
            <FontAwesomeIcon className="fa" icon={faLinkedinIn} />
          </div>
          <div className="d-flex align-items-end newsletter-container">
            <div className="newsletter d-flex align-items-end">
              <img src={images.newsletter} alt="Evrotom" />
            </div>
            <div className="input">
              <input type="text" ref={inputRef} placeholder={translations.footer.input} onSubmit={handleSubmit} onKeyDown={handleKeyDown} />
              <div className="arrow-send" onClick={handleSubmit}>
                <img src={images.arrow} onClick={() => {}} />
              </div>
              {/* <div className='frame'/> */}
            </div>
          </div>
        </div>
        <div className="middle d-flex">
          <div className="address d-flex flex-column">
            <span>{translations.footer.address.line1}</span>
            <span>{translations.footer.address.line2}</span>
            <span>{translations.footer.address.line3}</span>
            <a className="email" href="mailto:evrotom@hotmail.com">
              evrotom@hotmail.com
            </a>
          </div>
          <div className="links d-flex flex-column">
            <Link to="/contact">{translations.footer.contactUs}</Link>
            <Link to="/shop">{translations.navigation.shop}</Link>
            <Link to="/">{translations.navigation.home}</Link>
          </div>
          <div className="links-two d-flex flex-column">
            <a href={variables.myAccount}>{translations.footer.login}</a> 
          </div>
        </div>
        <div className="bottom">
          <div className="copyright d-flex">
            <div className="left d-flex">
              <span>
                © {new Date().getFullYear()} <Link to="/">Evrotom.</Link> All
                Rights Reserved.
              </span>
            </div>
            <div className="right">
              <span>
                Design by{" "}
                <a href="https://highlandesigns.com/">highland studio</a>
              </span>
            </div>
          </div>
          <div className="social d-flex">
            <a target='_blank' href='https://www.youtube.com/user/evrotom'><FontAwesomeIcon className="fa" icon={faYoutube} /></a>
            <a target='_blank' href='https://facebook.com/evrotom.ruma/'><FontAwesomeIcon className="fa" icon={faFacebookF} /></a>
            <a target='_blank' href='https://www.linkedin.com/'><FontAwesomeIcon className="fa" icon={faLinkedinIn} /></a>
          </div>
        </div>
      </div>
    </footer>
  )
})

export default Footer
