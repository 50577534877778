import * as React from 'react'

const useHomeAndFooterHeight = (): [number, number] =>
{
    const [homeHeight, setHomeHeight] = React.useState<number>(0);
    const [footerHeight, setFooterHeight] = React.useState<number>(0);

    const w = typeof window !== "undefined" ? window : null;

    React.useEffect(() =>
    {
        const updateSectionsHeight = () =>
        {
            setHomeHeight(document.querySelector('.home')?.clientHeight ?? 200);
            setFooterHeight(document.querySelector('footer').offsetTop);
        }
        updateSectionsHeight();
        w.addEventListener("resize", updateSectionsHeight);
        return () => w.removeEventListener("resize", updateSectionsHeight)
    }, [])
   return [homeHeight, footerHeight];
}

export default useHomeAndFooterHeight
