import * as React from 'react'
import { images, variables } from '../../data/variables'
import './Navbar.scss';
import { dropdownLanguages } from '../../translations/languages';
import {observer} from 'mobx-react-lite';
import { store } from '../../translations/store';
import { translations } from '../../translations/translations';
import { Link as ScrollLink } from 'react-scroll';
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import './Hamburger.scss';
import useHomeAndFooterHeight from '../hooks/sectionHooks';

const navLinks = () => [
    {
        link: '/',
        translation: translations.navigation.home,
    },
    {
        link: 'about-us',
        translation: translations.navigation.aboutUs,
    },
    {
        link: 'gallery',
        translation: translations.navigation.gallery,
    },
    {
        link: 'assortment',
        translation: translations.navigation.assortment,
    },
    {
        link: 'shop',
        translation: translations.navigation.shop,
    },
    {
        link: 'contact',
        translation: translations.navigation.contact,
    }
];

const Navbar = observer(() => {
    const [toggle, setToggle] = React.useState<boolean>(false);
    const [homeHeight, footerHeight] = useHomeAndFooterHeight();
    const homeRef = React.useRef(null);
    const logoRef = React.useRef(null);
    const location = useLocation();

    const links = () => {
        return navLinks().map(({ link, translation }) => {
            if (link === 'shop') {
                return <li key={link} className='nav-link'><a href={store.shopLink}>{translation}</a></li>
            }
            if (location.pathname.split('/')?.[1] === '' && link === '/') {
                return <li key={link} className='nav-link'><ScrollLink activeClass="active" to={'home'} spy={true} smooth={true}>{translation}</ScrollLink></li>;
            }
            else if (location.pathname.split('/')?.[1] === 'contact' || link === 'contact') {
                const to = link === 'contact' ? `/${link}` : `/#${link}`;
                return <li key={link} className='nav-link'><Link activeClassName='active' to={to}>{translation}</Link></li>
            }
            else {
                return <li key={link} className='nav-link'><ScrollLink activeClass="active" to={link} spy={true} smooth={true}>{translation}</ScrollLink></li>;
            }
        });
    };

    React.useEffect(() =>
    {
        const w = typeof window !== "undefined" ? window : null;
        const handleScroll = () =>
        {
            if (w.screen.width > 1095)
            {
                const isHamburgerMenu = homeRef.current?.classList.contains('minimized');
                if (w.scrollY > homeHeight - 70)
                {
                    if (!isHamburgerMenu)
                    {
                        homeRef.current?.classList.add('minimized');
                    }
                }
                else if (isHamburgerMenu)
                {
                    homeRef.current?.classList.remove('minimized');
                }
            }
            if (w.screen.width < 769)
            {
                if (w.scrollY > footerHeight - 125)
                {
                    logoRef.current?.classList.add('logo-hidden');
                }
                else if (logoRef.current?.classList.contains('logo-hidden'))
                {
                    logoRef.current?.classList.remove('logo-hidden');
                }
            }
        }
        w.addEventListener('scroll', handleScroll);

        return () => {
            w.removeEventListener('scroll', handleScroll, true);
        }
    }, [homeHeight, footerHeight]);

    return (
        <nav className={'navigation '} ref={homeRef}>
            <div className='inner'>
                <div className='logo' ref={logoRef}>
                    <Link to='/'><img src={images.logo} alt='evrotom' /></Link>
                </div>
                <div className='nav-links'>
                    <div className={'inner ' + (toggle ? 'active-menu' : '')}>
                        <ul className='links'>
                            {links()}
                            <li className='nav-link relative my-account'>
                                <a href={variables.myAccount}><img src={images.beekeeper} className='mr-05'/>{translations.navigation.myAccount}</a>
                                <div className='account-dropdown'>
                                    <div className='inner-section'>
                                        <p><a href={variables.myAccount} target='_blank'>{translations.navigation.login}</a></p>
                                        <p><a href={variables.registration} target='_blank'>{translations.navigation.registration}</a></p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div className='language position-relative'>
                            <span>{store.language}</span>
                            <div className='dropdown d-flex flex-column'>
                                {dropdownLanguages.filter(l => l !== store.language).map(l => <span key={l} onClick={() => store.changeLanguage(l)}>{l}</span>)}
                            </div>
                        </div>
                        {/* <div className={'hamburger'} onClick={() => setToggle(!toggle)}>
                            <div className='toggle-button'>
                                <img src={images.hexagon} />
                                <img src={images.hexagon} />
                                <img src={images.hexagon} />
                            </div>
                        </div> */}
                        <button className={"hamburger hamburger--spring " + (toggle ? 'is-active' : '')} type="button" onClick={() => setToggle(!toggle)}>
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                        </button>
                        {toggle && 
                        <div className='mobile-nav'>
                            <div className='mobile-nav-inner'>
                                <ul>
                                    {links()}
                                    <li className='nav-link'><a href={variables.myAccount}>{translations.navigation.myAccount}</a></li>
                                </ul>
                                <div className='mobile-languages'>
                                    {dropdownLanguages.map(l => <span key={l} onClick={() => store.changeLanguage(l)} className={(store.language === l ? 'active-language' : '')}>{l}</span>)}
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </nav>
    )
})


export default Navbar
