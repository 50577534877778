import { Language } from "./languages"
import { observable, makeObservable, action } from "mobx"
import { getItem, setItem } from "../lib/localStorage"
import { variables } from "../data/variables"

class Store {
  language: Language = (getItem("language") as Language) ?? "sr"
  initialLoad: boolean = false
  userInfo = null
  shopLink = variables.shop;

  constructor() {
    makeObservable(this, {
      language: observable,
      initialLoad: observable,
      changeLanguage: action,
      setInitialLoad: action,
    });
    this.shopLink = this.language === 'sr' ? variables.shop : variables.enShop;
  }

  changeLanguage(lang: Language) {
    this.language = lang
    this.shopLink = lang === 'sr' ? variables.shop : variables.enShop;
    setItem("language", lang)
  }

  setInitialLoad() {
    this.initialLoad = true
  }
}

export const store = new Store()
