const storage = typeof window !== `undefined` ? localStorage : null

export function setItem(key: string, value: string) {
  if (!value) return
  storage?.setItem(key, value)
}

export function getItem(key: string) {
  return storage?.getItem(key)
}
